import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "modal" ]
  go(e){
    e.preventDefault()
    this.close()
    
    console.log('test')
  }
}